/*
 * File Created: Thursday, 14th July 2022 3:12:55 pm
 * Author: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Last Modified: Monday, 3rd October 2022 5:58:37 pm
 * Modified By: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Copyright 2022 Austrian Center For Digital Production
 * -----
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
 * IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY.
 */

<template>
  <footer>
    <div class="footer-column"></div>
    <div class="footer-column">
      <h1 v-if="demo" style="color:red;"> DEMO VERSION </h1>
    </div>
    <!-- <div class="footer-column"></div> -->
    <div class="footer-column">
      <div class="logo-footer">
        <section>{{ $t("misc.footerPoweredBy") }}:</section>
        <a class="logo-footer" href="https://acdp.at"><img src="../assets/cdp_logo.png" alt="CDP Logo" /></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed:{
    demo(){
      return process.env.VUE_APP_DEMO === "true"
    }
  }
}
</script>

<style scoped>
footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}

.footer-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-column>a>img {
  object-fit: cover;
  max-height: 100%;
  width: 100%;
}

.logo-footer{
  /* float: right; */
  justify-self: right;
  height: 100%;
  width: 100%;
  margin: 0 auto 0 auto;
}

.logo-footer>section{
  font-size: var(--font-size-p);
  color:var(--secondary-background-color);

}


.logo-footer>a>img{
  object-fit: cover;
  max-height: 100%;
  width: 100%;
}

@media only screen and (max-width: 915px) {
  footer {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
</style>