/*
 * File Created: Thursday, 14th July 2022 3:12:55 pm
 * Author: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Last Modified: Monday, 3rd October 2022 5:57:32 pm
 * Modified By: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Copyright 2022 Austrian Center For Digital Production
 * -----
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
 * IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY.
 */

// Dev


// Prod



// Both
export default{
    language: ["en", "de"],
    test: "test"
}
