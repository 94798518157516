import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router';
import store from './store';
import VueApexCharts from "vue3-apexcharts";
import Notifications from '@kyvg/vue3-notification';
import BootstrapTable from '@oskargert/bootstraptable'
import RecursiveExpandableList from '@oskargert/recursiveexpandablelist'
import { VueCookieNext } from 'vue-cookie-next';
import  io  from 'socket.io-client'; //install: npm install vue-socket.io-extended socket.io-client
import "bootstrap/dist/css/bootstrap.min.css"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

if (process.env.NODE_ENV === 'development'){
    // Local dev
    app.config.globalProperties.$socket_backend = io(process.env.VUE_APP_BACKEND_URL, { path: "/ws/socket.io", transports: ['websocket','polling','flashsocket'] });
}else{
    // Docker + Nginx
    app.config.globalProperties.$socket_backend = io("", { path: "/backend", transports: ['websocket', 'polling', 'flashsocket'] })
}

app.component('VueDatePicker', VueDatePicker);

app.use(i18n)
app.use(router)
app.use(store)
app.use(Notifications)
app.use(VueApexCharts)
app.use(VueCookieNext)
app.use(BootstrapTable)
app.use(RecursiveExpandableList)
// app.use(VueDatePicker)
app.mount('#app')
