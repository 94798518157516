
export default {
  namespaced: true,
  state: {
    id: null,
  },

  mutations: {
    setId(state, id) {
      state.id = id;
    }
  },

  actions: {
    setId(context, id) {
      context.commit("setId", id)
    }
  }
}

