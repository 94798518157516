import { createStore } from 'vuex'
import PartModule from './part.module.js'
import IssueModule from './issue.module.js'
import DeviceModule from './device.module.js'
export default createStore({
    modules: {
        issue: IssueModule,
        part: PartModule,
        device: DeviceModule
    }
})
