export default {
  "navigation": {
    "Collect Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten Sammeln"])},
    "Fix Issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleme beheben"])},
    "Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])}
  },
  "analytics": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Analytik für Ausgabecode \"", _interpolate(_named("issueCode")), "\""])},
    "issueCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabecodes"])},
    "issuePositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe Positionen"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden noch keine Daten gesammelt"])}
  },
  "fix": {
    "issueList": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleme (Häufigkeit)"])},
      "noissues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit keine offenen Fragen"])}
    },
    "characteristics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus, ob die Merkmale innerhalb ihres Bereichs liegen"])},
      "tableHeader": {
        "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkmale"])},
        "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerhalb der Reichweite"])}
      }
    },
    "operatorList": {
      "tableHeader": {
        "RankingScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Score"])},
        "Cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursache"])},
        "Failuremode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
        "Mitigationname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeidungsmaßnahme"])},
        "Mitigationcomment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "Solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösung"])}
      },
      "noCauses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für den ausgewählten Problemcode wurden keine Ursachen ausgewählt"])},
      "popUp": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Ursache als Lösung ausgewählt, das Problem wird nicht aus den offenen Problemen entfernt"])},
        "provideFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie eine neue Lösung gefunden? Bitte geben Sie uns ein Feedback"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback abgeben"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
      }
    }
  },
  "history": {
    "partList": {
      "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile-ID eingeben oder scannen"])}
    },
    "noParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teile für den gewählten Zeitrahmen"])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ausgewählte Positionen für den Ausgabencode \"", _interpolate(_named("issueCode")), "\""])}
  },
  "collect": {
    "partList": {
      "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil auswählen oder scannen"])},
      "popUp": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie alle Ausgabecodes für das Teil \"", _interpolate(_named("partId")), "\" hinzugefügt haben"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
      }
    },
    "positions": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wählen Sie die Positionen für den Problemcode \"", _interpolate(_named("issueCode")), "\" aus"])},
      "noPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Positionen zugewiesen, der Emissionscode wird ohne Positionsdaten übermittelt"])},
      "graph": {
        "bar": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteilung der Ausgaben"])},
          "yAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit"])},
          "xAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen"])}
        },
        "timeSeries": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit für ausgewählte Positionen"])},
          "yAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit"])},
          "xAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitstempel"])}
        },
        "filterButtons": {
          "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
          "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
          "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
          "Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunde"])}
        }
      }
    }
  },
  "misc": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
    "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöst"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden"])},
    "footerPoweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "UI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI"])},
    "Grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raster"])},
    "notification": {
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Demomodus werden keine Daten übermittelt"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden übermittelt"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten konnten nicht übermittelt werden"])}
    }
  }
}