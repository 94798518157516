export default{
  namespaced: true,
  state: {
    code: "",
    label: "",
    name: "",
    positions: false
  },

  mutations: {
    setCode(state, code) {
      state.code = code;
    },
    setLabel(state, label) {
      state.label = label;
    },
    setName(state, name) {
      state.name = name;
    },
    setPositions(state, isSet) {
      state.positions = isSet;
    }
  },

  actions: {
    setCode(context, code) {
      context.commit("setCode", code)
    },
    setLabel(context, label) {
      context.commit("setLabel", label)
    },
    setName(context, name) {
      context.commit("setName", name)
    },
    setPositions(context, isSet) {
      context.commit("setPositions", isSet)
    }
  }
}


