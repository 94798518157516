export default {
  namespaced: true,
  state: {
    isMobile: false,

  },

  mutations: {
    setIsMobile(state, code) {
      state.isMobile = code;
    }
  },

  actions: {
    setIsMobile(context, code) {
      context.commit("setIsMobile", code)
    }
  }
}


