<template>
    <header class="header-custom">
        <nav class="navbar navbar-expand-lg" style="z-index:100">

                <button class="btn showMobile" type="button" @click="this.menuClosed = !this.menuClosed">
                    <div class="hamburger-menu" @click="transformMenu">
                        <span class="line line1" :class="{ line1transform: !menuClosed }"></span>
                        <span class="line line2" :class="{ line2transform: !menuClosed }"></span>
                        <span class="line line3" :class="{ line3transform: !menuClosed }"></span>
                    </div>
                </button>

                <router-link class="navbar-brand px-3" :to="'/'">
                    <img v-if="logoSrc" :src="logoSrc" alt="CDP Logo" />
                    <h1 v-if="appName" style="color: var(--accent-color)">{{ appName }}</h1>
                </router-link>

                <div v-if="!menuClosed">
                    <slot name="headSlot"></slot>
                </div>

                <div class="navbar-collapse" :class="{ 'collapse': menuClosed }">
                    <div class="navbar-nav">
                        <router-link v-for="navItem in navItems" :key="navItem.path" class="nav-link" :to="navItem.path" @click="menuClosed = true">{{
                            navItem.name }}</router-link>
                    </div>
                </div>

                <div v-if="menuClosed">
                    <slot name="headSlot"></slot>
                </div>

        </nav>

    </header>
</template>

<script>
export default {
    props: {
        /**
         * The pages to link to
         */
        navItems: {
            type: Array,
            required: true,
            default() {
                return [];
            },
            note: "The pages to link to",
        },
        logoSrc: {
            type: String,
            required: false
        },
        appName: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            menuClosed: true
        }
    }
}
</script>

<style>
/* Hamburger Menu CSS */

.showMobile {
    display: none !important;
}

.hamburger-menu {
    height: 26px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-self: left;
    cursor: pointer;
}

.line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--accent-color);
}

.line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.line2 {
    transition: transform 0.2s ease-in-out;
}

.line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.line1transform {
    transform: rotate(45deg);
}

.line2transform {
    transform: scaleY(0);
}

.line3transform {
    transform: rotate(-45deg);
}


.router-link {
    color: inherit
}

@media only screen and (max-width: 991px) {
    .showMobile {
        display: block !important;
    }
}
</style>