/*
 * File Created: Friday, 26th August 2022 3:46:18 pm
 * Author: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Last Modified: Monday, 3rd October 2022 5:58:23 pm
 * Modified By: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Copyright 2022 Austrian Center For Digital Production
 * -----
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
 * IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY.
 */

<!-- https://blog.flycode.com/step-by-step-how-to-create-a-vue-multi-language-app-with-vue-i18n -->

<template>
  <select v-model="$i18n.locale" @change="(event) => {$cookie.setCookie('locale', event.target.value); $router.go()}">
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale }}
    </option>
  </select>
</template>

<script>
/**
 * Simple component for language selection
 */
import Constants from "../Constants.js";
export default {
  name: "LanguageSelector",
  data() {
    return { locales: Constants.language };
  },
  mounted(){
    if(this.$cookie.isCookieAvailable("locale")){
      this.$i18n.locale = this.$cookie.getCookie("locale")
    }else{
      this.$cookie.setCookie("locale", this.$i18n.locale) 
    }
  }

};
</script>