export default {
  "navigation": {
    "Collect Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect Data"])},
    "Fix Issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix Issues"])},
    "Analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
  },
  "issueCodeNavigator": {
    "noIssueCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No issue codes for the given part id"])}
  },
  "analytics": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Analytics for issue code \"", _interpolate(_named("issueCode")), "\""])},
    "issueCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue codes"])},
    "issuePositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue positions"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data has been collected yet"])}
  },
  "history": {
    "partList": {
      "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter or scan part id"])}
    },
    "noParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No parts for the selected timeframe"])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selected positions for issue code \"", _interpolate(_named("issueCode")), "\""])}
  },
  "collect": {
    "partList": {
      "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or scan part"])},
      "popUp": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you have added all the issuecodes for part \"", _interpolate(_named("partId")), "\""])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "positions": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select the positions for the issue code \"", _interpolate(_named("issueCode")), "\""])},
      "noPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No positions assigned, issue code will be submitted without position data."])},
      "graph": {
        "bar": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue distribution"])},
          "yAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occurrences"])},
          "xAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions"])}
        },
        "timeSeries": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occurrences for selected positions"])},
          "yAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occurrences"])},
          "xAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])}
        },
        "filterButtons": {
          "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
          "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
          "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
          "Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])}
        }
      }
    }
  },
  "fix": {
    "issueList": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Issues (occurrences)"])},
      "noissues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently no open issues"])}
    },
    "characteristics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select if the characteristics are within their range"])},
      "tableHeader": {
        "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characteristics"])},
        "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within Range"])}
      }
    },
    "operatorList": {
      "tableHeader": {
        "RankingScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Score"])},
        "Cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause"])},
        "Failuremode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure Mode"])},
        "Mitigationname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitigation Action"])},
        "Mitigationcomment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "Solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])}
      },
      "noCauses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No causes for the selected issue code"])},
      "popUp": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cause was selected as a solution, issue will not be removed from open issues"])},
        "provideFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found a new solution? Please provide some feedback"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Feedback"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      }
    }
  },
  "misc": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solved"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "footerPoweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "UI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI"])},
    "Grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
    "notification": {
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data will be submitted in demo mode"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data has been submitted"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data could not be submitted"])}
    }
  }
}