/*
 * File Created: Thursday, 14th July 2022 3:12:55 pm
 * Author: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Last Modified: Monday, 3rd October 2022 5:57:23 pm
 * Modified By: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Copyright 2022 Austrian Center For Digital Production
 * -----
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
 * IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY.
 */


export function get_full_url(url){
    // console.log("Base url:", process.env.VUE_APP_BACKEND_URL)
    if(process.env.VUE_APP_BACKEND_URL != undefined){
        return process.env.VUE_APP_BACKEND_URL + url
    }else{
        return url
    }
}

function GET(url) {

    return fetch(url, {
        method: 'GET',
        credentials: 'include'
    })
}

function DELETE(url, data, headers) {
    return fetch(url, {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify(data)
    })
}

// TODO: Figure out failure response
async function POST(url, data, headers) {

    return await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    })
        .then((response) => {
            console.log('Success')
            console.log(response)
        }, (response) => {
            console.log('Error')
            console.log(response)
        });

}

export async function saveQuery(data, url) {
    await POST(url, data, { 'Content-Type': 'application/json' })
}

export async function getJsonData(url) {
    let response = await GET(url)
    return response.status === 200 ? response.json() : []
}

export async function rankCauses(data) {

    try {
        let returnJson = []
        const url = get_full_url("/issueCauses")
        const headers = { 'Content-Type': 'application/json' }
        let response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
        if (response.ok) returnJson = response.json()
        return returnJson
    } catch (error) {
        console.log(error)
    }
}

export async function deleteQuery(url, data) {
    let queries = []
    let response = await DELETE(url, data, { 'Content-Type': 'application/json' })
    queries = response.json()
    return queries
}

