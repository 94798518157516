import { createRouter, createWebHistory } from 'vue-router';
import { get_full_url } from "./BackendHandler";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: "Home",
      // component: () => import('./pages/OperatorList'),
      props: true,
      meta: {
        requiresAuth: false,
        showInNav: false
      }
    },
    {
      path: '/collect',
      name: "Collect Data",
      component: () => import('./pages/CollectData'),
      props: true,
      meta: {
        requiresAuth: false,
        showInNav: true
      },
      children:[
        {
          path: 'positions/:issueCode',
          name: "Positions",
          component: () => import('./pages/IssueCodePositions'),
          props: true,
          meta: {
            requiresAuth: false,
            showInNav: false
          },
        }
      ]
    },
    {
      path: '/fix',
      name: "Fix Issues",
      component: () => import('./pages/FixIssue'),
      props: true,
      meta: {
        requiresAuth: false,
        showInNav: true
      },
      children:[
        {
          path: 'OperatorList/:issueCode',
          name: "Operator List",
          component: () => import('./pages/OperatorList'),
          props: true,
          meta: {
            requiresAuth: false,
            showInNav: false
          }
        },
        {
          path: 'characteristics/:issueCode',
          name: "Characteristics",
          component: () => import('./pages/IssueCodeCharacteristics'),
          props: true,
          meta: {
            requiresAuth: false,
            showInNav: false
          }
        },
      ]
    },
    {
      path: '/analytics',
      name: "Analytics",
      component: () => import('./pages/IssueAnalytics'),
      props: true,
      meta: {
        requiresAuth: false,
        showInNav: true
      },
      children:[
        {
          path: ':issueCode',
          name: "Visaulization",
          component: () => import('./pages/IssueCodeVisualization'),
          props: true,
          meta: {
            requiresAuth: false,
            showInNav: false
          }
        },
        // {
        //   path: 'characteristics/:issueCode',
        //   name: "Characteristics",
        //   component: () => import('./pages/IssueCodeCharacteristics'),
        //   props: true,
        //   meta: {
        //     requiresAuth: false,
        //     showInNav: false
        //   }
        // },
      ]
    },
    {
      path: '/history',
      name: "History",
      component: () => import('./pages/PartHistory'),
      props: true,
      meta: {
        requiresAuth: false,
        showInNav: true
      },
    },
    {
      path: '/settings',
      name: "Settings",
      component: () => import('./pages/AppSettings'),
      props: true,
      meta: {
        requiresAuth: false,
        showInNav: false
      },
    },
  ]
});

// Can be used to oppose rules for routing
router.beforeEach((to, from) => {
  console.log("routing:", from.fullPath, "->", to)
  fetch(get_full_url("/logUrlChange"), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({fromPath: from.fullPath, toPath: to.fullPath})
  })
});

export default router;