/*
 * File Created: Thursday, 10th November 2022 9:20:11 am
 * Author: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Last Modified: Tuesday, 15th November 2022 3:48:20 pm
 * Modified By: Oskar Gert (oskar.gert@acdp.at)
 * -----
 * Copyright 2022 Austrian Center For Digital Production
 * -----
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
 * IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY.
 */

<template>
  <div class="app-layout">
    <div class="header p-3">
      <BootstrapHeader :navItems="navItems" :appName="'Expert System'">
        <template #headSlot>
          <div class="py-2" :class="{ navtransform: !menuClosed }">
            <LanguageSelector />
          </div>
        </template>
      </BootstrapHeader>
    </div>
    <div class="content-container p-3">
      <router-view></router-view>
    </div>
    <div class="footer p-3">
      <Footer> </Footer>
    </div>
  </div>

  <notifications group="start" duration="-1" />
  <notifications group="end" />
  <notifications :ignoreDuplicates="true" />
</template>

<script>
import LanguageSelector from "./components/LanguageSelector.vue";
import BootstrapHeader from "./components/BootstrapHeader.vue";
import Footer from "./components/PageFooter.vue";
export default {
  name: "App",
  components: {
    LanguageSelector,
    BootstrapHeader,
    Footer,
  },
  created() {
    this.$store.dispatch("device/setIsMobile", screen.width <= 760)
  },
  data() {
    return {
      characteristics: {},
      navItems: this.$router.getRoutes().filter((path) => path.meta.showInNav === true),
      screenWidth: screen.width
    };
  },
};
</script>

<style>
/* Define variables to reuse throughout your css */
/*   --main-background-color: rgb(204, 220, 255);  
  --secondary-background-color: #dfdfdf; 
    --button-default-color: #eafaf1;
      --border-color: grey;
*/
:root {
  --header-height: 100px;
  --footer-height: 100px;

  --app-width: 100%;
  --app-height: 100vh;

  --side-bar-width: 90vw;

  --main-background-color: #e1e6ee;
  --secondary-background-color: #004d9d;
  --accent-color: #004d9d;
  --selected-or-hover-color: #EEE;

  --text-default-color: rgb(36, 35, 35);

  --button-default-color: white;
  --button-back-color: grey;
  --button-cancel-color: #fff080;
  --button-submit-color: #4CAF50;
  /* Very light green #eafaf1 */
  --button-delete-color: #DE3163;



  --border-thickness: 1px;
  --border-type: solid;
  --border-color: grey;

  /* Responsive text vars */
  font-size: clamp(16px, 0.8vw + 0.2rem, 20px);
  --font-size-XL: 5rem;
  --font-size-h1: 1.75rem;
  --font-size-h2: 1.5rem;
  --font-size-h3: 1.25rem;
  --font-size-h3: 1.15rem;
  --font-size-p: 1rem;
  --font-size-small: 0.75rem;
}

@media only screen and (max-width: 915px) {
  :root {
    --font-size-XL: 4rem;
    --font-size-h1: 1.5rem;
    --font-size-h2: 1.25rem;
    --font-size-h3: 1rem;
    --font-size-h4: 0.9rem;
    --font-size-p: 0.75rem;
    --font-size-small: 0.6rem;
  }
}

body {
  scrollbar-gutter: stable;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  font-size: var(--font-size-h1) !important;
  color: var(--text-default-color);
  text-align: center;
}

h2 {
  font-size: var(--font-size-h2) !important;
  color: var(--text-default-color);
  text-align: center;
}

h3 {
  font-size: var(--font-size-h3) !important;
  color: var(--text-default-color);
  text-align: center;
}

h4 {
  font-size: var(--font-size-h4) !important;
  color: var(--text-default-color);
  text-align: center;
}

p {
  font-size: var(--font-size-p) !important;
  color: var(--text-default-color);
}

.small-font-size {
  font-size: var(--font-size-small);
}

.p-font-size {
  font-size: var(--font-size-small);
}

html {
  background: var(--main-background-color) !important;
}

.router-link-active {
  color: var(--accent-color) !important;
  border-bottom: 1px solid var(--accent-color) !important;
}

.app-layout {
  width: var(--app-width);
  height: var(--app-height);

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "body"
    "footer";
}

.header {
  box-sizing: border-box;

  grid-area: header;
  background: var(--main-background-color);
  padding: 3rem 0 3rem 0;

  /* border-bottom: 1px solid black; */
}

.content-container {
  position: relative;
  grid-area: body;

  background: var(--main-background-color);
  display: grid;
  grid-template-columns: 3fr 10fr;
  grid-template-areas:
    "sidebar content"
  ;
  /* justify-content: center; */
  padding: 3rem 0 3rem 0;
  gap: 1rem;
  transition: all 1.0s ease;
}

.sidebar {
  max-width: 30vw;
  position: relative;
  grid-area: sidebar;
  background: var(--main-background-color);
}

.footer {
  grid-area: footer;
  background: var(--main-background-color);
  box-sizing: border-box;
  padding: 3rem 0 3rem 0;
  /* border-top: 1px solid black; */
}

.thead-custom {
  --bs-table-bg: var(--secondary-background-color);
  --bs-table-color: white;
  height: 3rem;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-left {
  justify-content: left;
}

.flex-center {
  justify-content: center;
}

.flex-row-no-align {
  display: flex;
  justify-content: space-evenly;
  gap: 3%;
}

.submit-buttons {
  align-self: start;
  position: sticky;
  top: 40%;
  bottom: auto;
}

.button {
  height: 60px;
}

.button:hover {
  filter: brightness(80%);
}

/* .btn {
  min-width: 70px;
  min-height: 70px;
} */

.btn:hover {
  filter: brightness(80%);
}

.btn-submit {
  background: var(--button-submit-color);
}

.btn-back {
  background: var(--button-back-color);
}

.outlined {
  outline: var(--border-thickness) var(--border-type) var(--accent-color) !important;
}

/* tablet css */
@media only screen and (min-width: 915px) and (max-width: 1024px) {}

/* Phone css */
@media only screen and (max-width: 915px) {
  .header {
    grid-area: header;
    display: block;
    padding: 0;
  }

  .footer {
    padding: 0;
  }

  .sidebar {
    /* display: none; */
    display: block;
    position: fixed;
    height: 100%;
    /* width: var(--side-bar-width); */
    max-width: var(--side-bar-width);
    left: calc(var(--side-bar-width) * -1);
    transition: all 1.0s ease;
    z-index: 99;
  }

  .content-container {
    position: relative;
    grid-area: body;
    display: block;
    padding: 0;
  }

  .app-layout {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "body"
      "footer";
    margin: 0 auto 0 auto;
    width: var(--app-width);
    height: var(--app-height);
  }

  .btn {
    min-width: 40px;
    min-height: 40px;
  }

  .submit-buttons {
    top: auto;
    bottom: 0;
    width: 100%;
  }
}
</style>
